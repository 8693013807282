<!-- 商旅信息 -->
<template>
<div class="businessTravel">
    <input type="text" v-model="num" disabled style="display:none" />
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <div class="wCenter gulationsContentPar" v-if="config.c1&&config.c1.isShow&&list&&list.length>0">
        <div class="gulationsContent" v-for="(item,index) in list" :key="index">
            <p class="title3 hand" @click="handleToggle(index)">{{item.area}}
                <Icon type="ios-arrow-down" size="40" class="icon show" :class="{up:listShow[index]}" />
            </p>
            <div v-show="listShow[index]" class="contentBox">
                <p class="title1">签证相关信息</p>
                <div class="content" v-html="item.visaInfo"></div>
                <p class="title1" v-if="item.tripInfo">机票预订</p>
                <div class="content" v-html="item.tripInfo"></div>
                <p class="title1" v-if="item.hotelInfo">酒店住宿</p>
                <div class="content" v-html="item.hotelInfo"></div>
                <div class="downLoad">
                    <div class="wCenter">
                        <div class="downLoadItem" v-for="(item,index) in item.fileList" :key="index">
                            <div class="fileType">{{item.fileType&&item.fileType.toLocaleUpperCase()}}</div>
                            <p class="title">{{item.fileName}}</p>
                            <div class="btn">
                                <a :href="item.fileUrl" target="_blank" v-if="isSubmit=='1'" class="block">下载</a>
                                <span @click.stop="showModal=true" class='hand block'>下载</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 弹框 -->
    <section v-show="showModal" class="formModal">
        <FormContact @cancel='showModal=false'></FormContact>
    </section>
</div>
</template>

<script>
import {
    getPageConfigByCode,
    getListMerchantTour
} from '@/api/api'
import TopImg from '@/components/topImg'
import FormContact from '@/components/form-contact'
import Cookies from 'js-cookie'
export default {
    name: '',
    components: {
        TopImg,
        FormContact
    },
    computed: {
        isSubmit() {
            return Cookies.get('isSubmit')
        }
    },
    data() {
        return {
            list: [],
            config: {},
            num: 0,
            listShow: [],
            showModal: false,
        }
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'businessTravel/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                    let c1 = this.config.c1
                    if (c1 && c1.isShow) {
                        this.getList()
                    }
                }
            })
        },
        getList() {
            getListMerchantTour().then(res => {
                if (res.success) {
                    this.list = res.result
                    if (this.list.length > 0) {
                        this.setListShow()
                    }
                }
            })
        },
        setListShow() {
            this.listShow = Array(this.list.length).fill(false);
            this.listShow[0]=true
        },
        handleToggle(index) {
            this.listShow[index] = !this.listShow[index];
            this.num++;
        },
    },
}
</script>

<style lang="scss" scoped>
.businessTravel {
    .gulationsContentPar {
        margin-top: 0.3rem;
        margin-bottom: .7rem;

        @media (max-width:767px) {
            margin-top: 0.3rem;
        }
    }

    .gulationsContent {
        .contentBox {
            margin-bottom: 0.7rem;
        }

        .title1 {
            color: #ff1932;
            font-size: 0.22rem;
            margin: 0.3rem 0;
            font-weight: bold;
			@media (max-width:767px) {
			    font-size: 0.38rem 
			}
        }

        .title2 {
            color: #ffffff;
            font-size: 0.3rem;
            background: #000000;
            padding: 0.2rem 0.3rem;
            margin-bottom: 0.4rem;
        }

        .title3 {
            color: #ffffff;
            font-size: 0.24rem;
            background: #ff1932;
            padding: 0.1rem 0.2rem 0.1rem 0;
            margin: 0 0 0.1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width:767px) {
                font-size: 0.4rem;
            }

            .icon {
                cursor: pointer;
                transition: transform .2s;

                @media (max-width:767px) {
                    font-size: 24px !important;
                }

                &.up {
                    transform: rotate(180deg);
                }
            }
        }

        .title4 {
            color: #333333;
            font-size: 0.3rem;
            background: #eeeeee;
            padding: 0.2rem 0.3rem;
            margin: 0.7rem 0;
        }

        .title5 {
            color: #ffffff;
            font-size: 0.3rem;
            background: #ffb9a0;
            padding: 0.2rem 0.3rem;
            margin: 0.7rem 0;
        }

        .content {
            color: #333333;
            font-size: 0.2rem;
            line-height: 2;
            margin-bottom: 0.1rem;
            text-align: justify;
			@media (max-width:767px) {
			    font-size: 0.38rem 
			}
        }

        .downLoad {
            .wCenter {
                display: flex;
                flex-wrap: wrap;

                .fileType {
                    display: inline-block;
                    background-color: #aa1b27;
                    width: 1rem;
                    height: 1rem;
                    font-size: .3rem;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (max-width: 767px) {
                        font-size: .26rem;
                    }
                }

                @media (max-width: 767px) {
                    padding: 0;
                }
            }

            .downLoadItem {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 3rem;
                height: 3rem;
                border: 0.01rem solid #eeeeee;
                margin: 0.2rem 0.33rem 0 0;

                &:nth-child(4n) {
                    margin-right: 0;
                }

                @media (max-width: 767px) {
                    $w: 31%;
                   // $gutter: (100% - $w * 3)/2;
					$gutter: 3.5%;
                    width: $w;
                    margin: 0 $gutter $gutter 0;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }

                    &:nth-child(4n) {
                        margin-right: $gutter;
                    }
                }

                img {
                    width: 1rem;
                }

                .title {
                    color: #333333;
                    font-size: 0.24rem;
                    margin: 0.26rem 0 0.44rem 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 90%;
                    text-align: center;
					@media (max-width:767px) {
					    font-size: 0.38rem 
					}
                }

                .btn {
                    width: 2rem;
                    height: 0.5rem;
                    line-height: 0.5rem;
                    background: #333333;
                    color: #ffffff;
                    border-radius: 0.04rem;
                    text-align: center;
                    font-size: 0.24rem;
					@media (max-width:767px) {
					        height: 0.7rem;
					        line-height: 0.7rem;
							font-size: 0.32rem;
					}
                    a {
                        color: inherit;
                    }
                }
            }
        }
    }
}
</style>
